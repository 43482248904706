import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

import useHover from '@assets/scripts/hooks/useHover';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#FFFE54';
const footerRelatedLinks = [
	{
		title: 'Les chauffeurs de Rémy',
		url: '/fr/projets/les-chauffeurs-de-remy/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/mjf/mjf_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: { eq: "projects/mjf/mjf_hero_mobile.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_1: file(relativePath: { eq: "projects/mjf/mjf_1.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_2: file(relativePath: { eq: "projects/mjf/mjf_2.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1600, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_3: file(relativePath: { eq: "projects/mjf/mjf_3_FR.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_4: file(relativePath: { eq: "projects/mjf/mjf_4_FR.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_5: file(relativePath: { eq: "projects/mjf/mjf_5.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_6: file(relativePath: { eq: "projects/mjf/mjf_6.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_7: file(relativePath: { eq: "projects/mjf/mjf_7.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_8: file(relativePath: { eq: "projects/mjf/mjf_8.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_9: file(relativePath: { eq: "projects/mjf/mjf_9.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_10: file(relativePath: { eq: "projects/mjf/mjf_10.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_11: file(
					relativePath: { eq: "projects/mjf/mjfmedia_1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1400, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_12: file(
					relativePath: { eq: "projects/mjf/mjfmedia_2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1400, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_13: file(relativePath: { eq: "projects/mjf/mjf13.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1400, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{ uri: '/en/projects/montreux-jazz-festival/', lang: 'en' },
			]}
			title="Montreux Jazz Festival - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Montreux Jazz Festival"
				image={{
					...data.heroImage.childImageSharp,
					caption:
						"Rag'n'Bone Man en performance à Montreux, par Florian Aeby",
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
					caption:
						"Rag'n'Bone Man en performance à Montreux, par Florian Aeby",
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Identifier de nouveaux modèles d'affaires et les
							associer à une solution web pour l'un des festivals
							les plus emblématiques du monde.
						</p>

						<div className="list-two-columns">
							<ListText
								title="Nos services"
								list={[
									'Workshops de branding',
									'Workshops stratégiques',
									'Stratégie digitale',
									'Développement web',
								]}
							/>
						</div>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Le Montreux Jazz est un festival international
							emblématique pour son histoire légendaire et ses
							performances musicales hors normes. Afin de
							continuer à marquer les esprits, le festival a fait
							appel à superhuit pour identifier de nouveaux
							modèles d'affaires et pour développer un outil web
							permettant de servir ces marchés, tout en améliorant
							l'expérience utilisateur digitale du festival.
						</p>
						<h3>Notre réponse</h3>
						<p>
							Une stratégie de design basée sur de la recherche
							utilisateur, une analyse sectorielle, des workshops
							stratégiques avec les différentes équipes du MJF,
							beaucoup de collaboration et des sessions d'idéation
							permettant d'identifier de nouvelles possibilités.
						</p>
						<p>
							Nous avons repositionné la marque Montreux Jazz
							Festival et reconstruit son architecture. Tout ceci
							pour finalement aboutir à la production d’une
							solution Web sur mesure, offrant la flexibilité à un
							festival et l’évolutivité nécessaire pour lui
							permettre de faire transiter leurs modèles
							d'affaires sur le digital, le tout évidemment par
							étape. En s’assurant une expérience visuelle qui
							retranscrit l'ADN de la marque.
						</p>

						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.montreuxjazzfestival.com/"
								text="Découvrir le site"
							/>
							{/*
								<ButtonSecondary
								href="/todo"
								text="Case study"
								data-target
							/>
							 */}
						</div>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>ADN de la marque</h3>
							<p>
								Définir une personnalité de marque est un
								travail central au positionnement, nous avons
								entrepris des workshops et identifié des
								tensions et des terminologies qui définissent
								aujourd'hui un ADN spécifique.
							</p>
						</div>
						<Image {...data.mjf_1.childImageSharp} alt="Logo" />
					</div>

					{/* article à ajouter
					}<ItemArticle
						className="element-spacing"
						modifiers="small"
						link={{ title: 'Read article', url: '/todo' }}
						title="Type design and branding: The inevitable alliance"
					/>
					*/}
				</div>

				<Video
					className="col-full"
					src="f789a8b76450eaab08d714c622ecd2a5"
					alt="Page transition"
				/>

				<div className="box text-content" data-animation-page>
					<h3>Le Vinyl, héritage musical</h3>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								La démarche graphique est centrée sur la volonté
								de proposer un design élégant et intemporel, qui
								retranscrit à la fois l'univers musical des
								légendes comme l'ADN de la marque MJF. Cet axe
								facilite aussi la possibilité d'habiller le site
								avec l’affiche de l'édition.{' '}
							</p>
						</div>
						<div className="text-content">
							<p>
								Quoi d'autre que des disques vinyles pour
								exprimer au mieux cet héritage authentique de la
								musique. Ses rainures ont été traduites en
								quelques lignes simples, dans les visuels
								principaux du site.
							</p>
						</div>
					</div>
					<div>
						<Video
							src="a35774b7eb99aa6fd427fe8da3166e05"
							alt="Concept graphique d'animation"
						/>
					</div>
				</div>

				<Column>
					<div className="text-content column__sticky column__text">
						<h3>
							Une marque se retranscrit aussi par ses animations
						</h3>
						<p>
							Le site s'appuie sur des animations subtiles qui
							permettent de simplifier l'expérience utilisateur en
							apportant de l'information.
						</p>
						<p>
							Les animations ont aussi l'avantage de consolider
							l'expression de la marque.
						</p>

						{/*
						<ItemArticle
							className="element-spacing"
							modifiers="small"
							link={{ title: 'Read article', url: '/todo' }}
							title="Type design and branding: The inevitable alliance"
						/>
						*/}
					</div>

					<div className="text-content column__media">
						<Video
							src="8b7a7760b11dd2e11dce5305aa6a4bba"
							alt="Scroll dans le site MJF"
						/>
						<Video
							className="col-full"
							src="7404d786f2f12c6a5e22ed8123ca8638"
							alt="Vidéo d'animation du menu"
						/>
					</div>
				</Column>

				{/***** VALORISATION TYPO *****/}
				<div className="box text-content" data-animation-page>
					<h3>Evolution typographique</h3>
					<div className="list-two-columns align-items-center">
						<Image
							{...data.mjf_3.childImageSharp}
							alt="Typography"
						/>
						<Image
							{...data.mjf_4.childImageSharp}
							alt="Typography"
						/>
					</div>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								L'introduction d'une nouvelle police de titre;
								la Noe Text conçue par la fonderie indépendante{' '}
								<a
									href="https://www.schick-toikka.com/"
									target="_blank"
									rel="noopener noreferrer"
									data-target
									onMouseEnter={() => hoverOn()}
									onMouseLeave={() => hoverOff()}
								>
									Schick Toikka
								</a>{' '}
								permet d'affuter le concept de marque et
								d'augmenter l'unicité.
							</p>
						</div>
						<div className="text-content">
							<p>
								La nouvelle police fait un lien parfait entre
								héritage et innovation, elle renforce
								l'expression d'élégance tout en étant expressive
								et audacieuse.
							</p>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="bd01d26296ca47b369bd2340dfe2c854"
					alt="Plateforme Média"
				/>

				{/***** VALORISATION TYPO *****/}
				<div className="box text-content" data-animation-page>
					<h3>
						Transformer le site Web en une plateforme multimédia à
						part entière
					</h3>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								Une plateforme média a été développée pour
								cultiver l'héritage du Montreux Jazz Festival et
								générer une nouvelle source de revenus liée à un
								nouveau modèle musical mixte.
							</p>
							<p>
								Il permettra aux utilisateurs de plonger plus
								profondément dans la diversité des contenus du
								festival. Avec des formats spéciaux, permettant
								de dénicher les étoiles montantes.
							</p>
						</div>
						<div className="text-content">
							<p>
								Comme de découvrir de nouveaux styles musicaux
								et bien évidemment de revivre des concerts
								légendaires. La plateforme média est conçue pour
								évoluer en fonction des besoins futurs du
								Montreux Jazz Festival.
							</p>
							<p></p>
						</div>
					</div>
				</div>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.mjf_11.childImageSharp}
						alt="Plateforme média version mobile"
					/>
					<Image
						{...data.mjf_12.childImageSharp}
						alt="Plateforme média version mobile"
					/>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle="Témoignage client"
					quote="superhuit a été un véritable partenaire stratégique dans le développement de notre stratégie digitale et l’identification de nouveaux univers business."
					more="Ils ont proposé des ateliers attractifs et engageants, des sessions 1:1 pour concevoir et simplifier un portefeuille numérique complexe et multicanal. Cela a abouti à une magnifique expérience Web progressive qui peut évoluer selon les besoins de notre entreprise médias. C'est un partenariat très engagé et passionné, basé sur la confiance liée à leur expertise, avec toujours la créativité au cœur de tout ce que nous faisons ensemble."
					cite="Michelle Goff – Chief Digital Officer, Montreux Media Venture"
				/>

				<Video
					className="col-big"
					src="ff9de491119c285837f240ad598d0c4e"
					alt="Programme du MJF"
				/>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-top justify-normal">
						<div className="text-content">
							<h3>
								Amélioration de l’expérience utilisateur liée à
								la découverte du programme
							</h3>
						</div>
						<div className="text-content">
							<p>
								Avec près de 400 concerts, payants ou gratuits,
								répartis sur 11 scènes pendant 16 jours, le
								programme du Montreux Jazz Festival a ses
								complexités et défis. Un important travail de
								structuration de l'information a été effectué
								afin de simplifier sa compréhension et sa
								structure fonctionnelle
							</p>
							<p>
								<small>
									(Le programme affiché dans les exemples est
									fictif)
								</small>
							</p>
						</div>
					</div>
				</div>

				<Gallery className="col-full" modifiers="gutter" s>
					<Image
						{...data.mjf_8.childImageSharp}
						alt="Mobile Design"
					/>
					<Image
						{...data.mjf_9.childImageSharp}
						alt="Mobile Design"
					/>
				</Gallery>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
